import React from 'react';
import Head from 'next/head';

// [OSS Migration] Swap out aws to oss url
export const GoogleLogoSchema: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const googleLogoSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://glints.com/',
    logo: 'https://glints-dashboard.s3.ap-southeast-1.amazonaws.com/company-logo/805d861f71c172ce260a247028cb0718.png',
  };

  return (
    <Head>
      <script
        key="google-logo-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `${JSON.stringify(googleLogoSchema)}`,
        }}
      />
    </Head>
  );
};
